// angular
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding,  Input   } from '@angular/core';

// services
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';

// rxjs
import { Subject } from 'rxjs';
import { takeUntil,tap } from 'rxjs/operators';

// wml-components
import { WMLCustomComponent, WMLUIProperty, generateClassPrefix } from '@windmillcode/angular-wml-components-base';

// misc
import {ENV } from '@env/environment';
import { WMLTab, WMLTabHeader, WMLTabsParams, WMLTabsParamsUpdateTabsSubjParams } from '@windmillcode/angular-wml-tabs';
import { StoreService } from '@shared/services/store/store.service';
import { WmlButtonZeroParams } from '@windmillcode/angular-wml-button-zero';
import { PanelCartZeroComponent, PanelCartZeroParams } from '../panel-cart-zero/panel-cart-zero.component';


@Component({

  selector: 'panel-cart-container-zero',
  templateUrl: './panel-cart-container-zero.component.html',
  styleUrls: ['./panel-cart-container-zero.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush,

})
export class PanelCartContainerZeroComponent  {

  constructor(
    public cdref:ChangeDetectorRef,

    public utilService:UtilityService,
    public baseService:BaseService,
    public storeService:StoreService

  ) { }

  classPrefix = generateClassPrefix('PanelCartContainerZero')
  idPrefix = generateClassPrefix(ENV.idPrefix.panelCartContainerZero)
  @Input('params') params: PanelCartContainerZeroParams = new PanelCartContainerZeroParams()
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  @HostBinding('id') myId:string = this.idPrefix()
  ngUnsub= new Subject<void>()
  tabParams = new WMLTabsParams({
    tabs:[]
  })
  currentCartNumber = 1
  addCart = ()=>{
    if(this.storeService.carts.length > 3) return
    this.storeService.carts.push([])
    this.storeService.cartSubj.next("addCart")
  }
  removeCart = ()=>{
    if(this.storeService.carts.length <= 1) return
    this.storeService.carts.pop()
    this.storeService.cartSubj.next("removeCart")
  }
  addCartBtn = new WmlButtonZeroParams({
    text:new WMLUIProperty({
      text:"PanelCartContainer.addCartBtn"
    }),
    button:new WMLUIProperty({
      click:this.addCart
    })
  })
  removeCartBtn = new WmlButtonZeroParams({
    text:new WMLUIProperty({
      text:"PanelCartContainer.removeCartBtn"
    }),
    button:new WMLUIProperty({
      click:this.removeCart
    })
  })
  listenForTabChange= ()=>{
    return this.tabParams.tabChangedEvent
    .pipe(
      takeUntil(this.ngUnsub),
      tap((res)=>{
        this.currentCartNumber = res.index+1
        this.storeService.currentCart =this.storeService.carts[res.index]
      })
    )

  }
  listenForCartQuantity = ()=>{
    return this.storeService.cartSubj
    .pipe(
      takeUntil(this.ngUnsub),
      tap((res)=>{
        this.tabParams.tabs = this.storeService.carts
        .map((cart,index0)=>{
          return new WMLTab({
            body:new WMLCustomComponent({
              cpnt:PanelCartZeroComponent,
              params:new PanelCartZeroParams({
                cart,
                closePanel:this.params.close
              })
            }),
            header:{
              type:"wmlTabHeader",
              wmlTabHeader:new WMLTabHeader({
                text:[
                  new WMLUIProperty({
                    text:"PanelCartContainer.tabHeader"
                  }),
                  new WMLUIProperty({
                    text:(index0+1).toString()
                  })

                ]
              })
            }
          })
        })
        this.tabParams.updateTabsSubj.next(new WMLTabsParamsUpdateTabsSubjParams({
          currentTable:res ==="removeCart"? 0: this.tabParams.tabs.length-1
        }))
        this.cdref.detectChanges()
      })
    )

  }

  ngOnInit(): void {
    this.listenForCartQuantity().subscribe()
    this.listenForTabChange().subscribe()
    this.storeService.cartSubj.next("removeItem")
  }

  ngOnDestroy(){
    this.ngUnsub.next();
    this.ngUnsub.complete()
  }

}



export class PanelCartContainerZeroParams {
  constructor(params:Partial<PanelCartContainerZeroParams>={}){
    let origParams = Object.entries(params)
    .filter(([key,val]) => {
      return !key.startsWith('param');
    });
    Object.assign(this, { ...Object.fromEntries(origParams) })
  }
  close:Function
}


