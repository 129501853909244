// angular
import { ChangeDetectorRef, Component, HostBinding, Input } from '@angular/core';

// services
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';

// rxjs
import { of } from 'rxjs';
import { catchError, concatMap, takeUntil, tap } from 'rxjs/operators';

// wml-components
import { WMLImage, generateClassPrefix } from '@windmillcode/angular-wml-components-base';

// misc
import {ENV } from '@env/environment';




import { FormsService } from '@shared/services/forms/forms.service';
import { AccountsService } from '@shared/services/accounts/accounts.service';
import { SpecificService } from '@core/specific/specific.service';
import { WMLFileUploadParams, WmlFileUploadComponent } from '@windmillcode/angular-wml-file-manager';
import { readFileContent } from '@core/utility/file-utils';
import { UpdateUserUIRequestBody } from '@shared/services/accounts/updateUser';

@Component({
  selector: 'image-upload-zero',
  templateUrl: './image-upload-zero.component.html',
  styleUrls: ['./image-upload-zero.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageUploadZeroComponent extends WmlFileUploadComponent {
  constructor(
    public override  cdref:ChangeDetectorRef,
    public formsService: FormsService,
    public utilService: UtilityService,
    public baseService: BaseService,
    public accountsService: AccountsService,
    public specifcService:SpecificService
  ) {
    super(cdref)
  }

  override classPrefix = generateClassPrefix('ImageUploadZero');
  idPrefix = generateClassPrefix(ENV.idPrefix.imageUploadZero);
  @Input('params') override params: ImageUploadZeroParams = new ImageUploadZeroParams();
  @HostBinding('class') override myClass: string = this.classPrefix(`View`);
  @HostBinding('id') myId: string = this.idPrefix();



  mainFormVars = ENV.imageUploadZero.mainForm;
  mainFormGroup = this.formsService.imageUploadZero.mainForm;

  createMainFormField = (params) => {
    let {formControlName,createWMLFieldFn,errorMsgKeyArray,fieldCustomParams} = params;

    let field = this.baseService.createWMLField({
      i18nPrefix: 'ImageUploadZero.mainForm',
      idPrefixFn: this.idPrefix,
      formVars: this.mainFormVars,
      fieldParentForm: this.mainFormGroup,
      formControlName,createWMLFieldFn,errorMsgKeyArray,fieldCustomParams,
    });

    return field;
  };

  listenForAvatarUpload = ()=>{

    return this.params.formArray.valueChanges
    .pipe(
      takeUntil(this.ngUnsub),
      concatMap((res)=>{
        this.baseService.openOverlayLoading()
        let {file}= res[0]
        return readFileContent(file,'readAsDataURL')
      }),
      tap((res)=>{
        let uiReqBody =new UpdateUserUIRequestBody({
          accessToken :this.accountsService.currentUser.accessToken,
          users:[{
            id:this.accountsService.currentUser.id,
            photoUrl:{
              content:res.content,
              mimeType:res.file.type
            }
          }]
        })
        // for some reason cant use conncatMap here
        this.updateUser(uiReqBody).subscribe();

      }),
      catchError(()=>{
        return of({})
      }),
    )

  }

  updateUser(uiReqBody: UpdateUserUIRequestBody) {
    return this.accountsService.updateUser(uiReqBody)
      .pipe(
        takeUntil(this.ngUnsub),
        this.baseService.closeOverlayLoadingViaRxjsFinalize
      );
  }

  override ngOnInit(): void {
    this.params.img = new WMLImage({
      ...this.specifcService.getLogoImg(),
      ...this.params.img
    })
    this.params = new ImageUploadZeroParams({
      ...this.params,
      formArray:this.mainFormGroup.controls[this.mainFormVars.avatarUrlFormControlName],
    })
    this.listenForAvatarUpload().subscribe()

  }


}

export class ImageUploadZeroParams  extends WMLFileUploadParams {
  constructor(params: Partial<ImageUploadZeroParams> = {}) {
    super();
    let origParams = Object.entries(params).filter(
      ([key, val]) => !key.startsWith('param')
    );
    Object.assign(this, { ...Object.fromEntries(origParams) });
  }
  img = new WMLImage()
  override files =[]
}
