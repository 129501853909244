
// angular
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding,  Input   } from '@angular/core';

// services
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';

// rxjs
import { Subject, fromEvent, iif, of, zip } from 'rxjs';
import { concatMap, distinctUntilChanged, map, startWith, takeUntil,tap } from 'rxjs/operators';

// wml-components
import { WMLAnimateUIProperty, WMLAnimateUIPropertyState, generateClassPrefix } from '@windmillcode/angular-wml-components-base';

// misc
import {ENV } from '@env/environment';
import { WMLPopupParams } from '@windmillcode/angular-wml-popup';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { LabsService } from '@shared/services/labs/labs.service';
import { WmlButtonOneParams } from '@windmillcode/angular-wml-button-zero';
import { CSSVARS } from '@core/utility/common-utils';



@Component({

  selector: 'lab-viewer-zero',
  templateUrl: './lab-viewer-zero.component.html',
  styleUrls: ['./lab-viewer-zero.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush,

})
export class LabViewerZeroComponent  {

  constructor(
    public cdref:ChangeDetectorRef,
    public utilService:UtilityService,
    public baseService:BaseService,
    public sanitizer:DomSanitizer,
    public labsService:LabsService
  ) { }

  classPrefix = generateClassPrefix('LabViewerZero')
  idPrefix = generateClassPrefix(ENV.idPrefix.labViewerZero)
  @Input('params') params: LabViewerZeroParams = new LabViewerZeroParams()
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  @HostBinding('id') myId:string = this.idPrefix()
  ngUnsub= new Subject<void>()
  state : WMLAnimateUIPropertyState = "closed"
  videoUrl:SafeUrl
  trelloBoardUrl:SafeUrl
  markdownData:string
  READMEIsPresent = true

  toggleREADMEBtn = new WmlButtonOneParams({
    text:"LabViewerZero.toggleREADME",
    click:()=>{

      if(this.READMEIsPresent === false){
        this.baseService.createWMLNote("LabViewerZero.wmlNotify.noGuideAvailable")
      }
      else{
        this.readme.animationState === "open" ? this.readme.closeAnimation():this.readme.openAnimation()
      }
    }
  })
  title = new WMLAnimateUIProperty({
    class:this.classPrefix("Pod0Title0"),
    endOpenStyles:{
      opacity:"1"
    },
    endCloseStyles:{
      opacity:"0"
    },
    beginOpenStyles:{
      opacity:"1"
    }
  })
  video = new WMLAnimateUIProperty({
    class:this.classPrefix("Pod1Item0"),
    autoOpen:true,
    endOpenStyles:{
      left:"0%"
    },
    endCloseStyles:{
      left:"-100%"
    },
    beginOpenStyles:{
      left:"-100%"
    }

  })
  readme = new WMLAnimateUIProperty({
    class:this.classPrefix("Pod1Item1"),
    autoOpen:true,
    endOpenStyles:{
      left:"0%"
    },
    endCloseStyles:{
      left:"100%",
      display:"none"
    },
    beginOpenStyles:{
      display:"block",
      left:"100%",
    }
  })

  closeViewer=()=>{

    this.state = "closing"
    this.title.closeAnimation()
    this.video.closeAnimation()
    this.readme.closeAnimation()

  }

  listenForPopupClose=()=>{
    let obs$ =[
      this.title.animationEndEvent,
      this.video.animationEndEvent
    ]
    if(this.READMEIsPresent){
      obs$.push(this.readme.animationEndEvent)
    }
    return zip(obs$)
    .pipe(
      takeUntil(this.ngUnsub),
      tap((res)=>{
        if(this.state === "opening" || res.every((state)=> state ==="open")){
          this.state = "open"
        }
        if(this.state ==="closing" || res.every((state)=> state ==="closed")){
          this.params.popup?.closePopup?.()
          this.state = "closed"
          this.cdref.detectChanges()
        }
      })
    )

  }

  initAnimationObjects() {
    this.state = "opening"
    ;[this.title, this.video, this.readme]
    .forEach((uiProperty, index0) => {
      uiProperty.cdref =this.cdref
    });
  }

  getMarkdownText() {
    return iif(
      ()=>![null,undefined].includes(this.params.readmeUrl) && this.READMEIsPresent,
      this.labsService.getMarkdownText({
        url:(this.params.readmeUrl ??"")
        .split("github.com").join("raw.githubusercontent.com")
        .split("blob").join("")
      })
      .pipe(
        tap((result)=>{
          this.markdownData = result
          this.cdref.detectChanges()
        })
      ),
      of({})
      .pipe(
        takeUntil(this.ngUnsub),
        tap(()=>{
          this.readme.style.display = "none"
          this.cdref.detectChanges()
          this.READMEIsPresent = false
        })
      ),
    )
    .pipe(
      takeUntil(this.ngUnsub),
      concatMap(()=>{
        return this.listenForPopupClose()
      })

    )

  }

  listenForWindowResize = ()=>{
    let mobile = window.matchMedia(CSSVARS.wmlMobile)
    let tablet = window.matchMedia(CSSVARS.wmlTablet);
    let desktop = window.matchMedia(CSSVARS.wmlDesktop)
    return fromEvent(window,"resize")
    .pipe(
      startWith({}),
      takeUntil(this.ngUnsub),
      map(()=>tablet.matches || desktop.matches || mobile.matches),
      distinctUntilChanged(),
      tap(()=>{
        this.READMEIsPresent = !mobile.matches

      })
    )

  }

  ngOnInit(): void {
    if(this.params.videoUrl){
      this.videoUrl =this.sanitizer.bypassSecurityTrustResourceUrl(this.params.videoUrl)
    }
    if(this.params.trelloBoardUrl){
      this.trelloBoardUrl =this.sanitizer.bypassSecurityTrustResourceUrl(this.params.trelloBoardUrl)
    }
    this.listenForWindowResize().subscribe()
    this.getMarkdownText().subscribe();
    this.initAnimationObjects();
    this.cdref.detectChanges()
  }

  ngOnDestroy(){
    this.ngUnsub.next();
    this.ngUnsub.complete()
  }

}



export class LabViewerZeroParams {
  constructor(params:Partial<LabViewerZeroParams>={}){
    let origParams = Object.entries(params)
    .filter(([key,val]) => {
      return !key.startsWith('param');
    });
    Object.assign(this, { ...Object.fromEntries(origParams) })
  }
  title?:string
  videoUrl?:string
  readmeUrl?:string
  trelloBoardUrl?:string
  popup?:WMLPopupParams
}


