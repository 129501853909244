import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { checkForExpiryMonthAndYear } from '@core/utility/specific-utils/validators';
import {ENV } from '@env/environment';
import {RxwebValidators} from "@rxweb/reactive-form-validators";
@Injectable({
  providedIn: 'root'
})
export class FormsService {

  constructor(

  ) { }




  imageUploadZero={

    mainForm :new FormGroup({
    	[ENV.imageUploadZero.mainForm.avatarUrlFormControlName]:new FormArray([])
    })

  }

  accountProfileZero={

    mainForm :new FormGroup({
    	[ENV.accountProfileZero.mainForm.displayNameFormControlName]:new FormControl(""),
    	[ENV.accountProfileZero.mainForm.emailFormControlName]:new FormControl(""),
    	[ENV.accountProfileZero.mainForm.emailVerifiedFormControlName]:new FormControl(false),
    	[ENV.accountProfileZero.mainForm.phoneNumberFormControlName]:new FormControl("")
    }),
    notifyForm :new FormGroup({
      [ENV.accountProfileZero.notifyForm.emailMarketingConsentFormControlName]:new FormControl(false),
      [ENV.accountProfileZero.notifyForm.smsMarketingConsentFormControlName]:new FormControl(false)
    })

  }

  productDetailZero={

    mainForm :new FormGroup({
    	[ENV.productDetailZero.mainForm.quantityFormControlName]:new FormControl("",[
        Validators.required,
        Validators.min(0),
        RxwebValidators.digit()
      ]),
    	[ENV.productDetailZero.mainForm.colorFormControlName]:new FormArray([],Validators.required),
    	[ENV.productDetailZero.mainForm.sizeFormControlName]:new FormArray([],Validators.required),
    })

  }



  contactUsForm = {
    mainForm:new FormGroup({
      [ENV.contactUsForm.mainForm.nameFormControlName]:new FormControl(""),
      [ENV.contactUsForm.mainForm.emailFormControlName]:new FormControl("",[Validators.required,Validators.email]),
      [ENV.contactUsForm.mainForm.phoneFormControlName]:new FormControl(""),
      [ENV.contactUsForm.mainForm.companyFormControlName]:new FormControl(""),
      [ENV.contactUsForm.mainForm.descFormControlName]:new FormControl("",Validators.required),
    })
  }



  accountBillingZero = {
    addressForm : new FormGroup({
      [ENV.accountBillingZero.addressForm.streetAdrFormControlName]:new FormControl("",Validators.required),
      [ENV.accountBillingZero.addressForm.secondaryAdrFormControlName]:new FormControl(""),
      [ENV.accountBillingZero.addressForm.cityFormControlName]:new FormControl("",Validators.required),
      [ENV.accountBillingZero.addressForm.stateFormControlName]:new FormControl("",Validators.required),
      [ENV.accountBillingZero.addressForm.zipcodeFormControlName]:new FormControl("",Validators.required),
      [ENV.accountBillingZero.addressForm.countryFormControlName]:new FormControl({value:"US",disabled:true},Validators.required)
    }),

    creditCardForm :new FormGroup({
      [ENV.accountBillingZero.creditCardForm.cardNumberFormControlName]:new FormControl(
        "",[Validators.required,RxwebValidators.creditCard({creditCardTypes:[ "Visa", "AmericanExpress", "Maestro", "JCB", "Discover", "DinersClub", "MasterCard"]})]
      ),
      [ENV.accountBillingZero.creditCardForm.expiryDateFormControlName]:new FormGroup({
        month:new FormControl(""),
        year: new FormControl(""),
      },[checkForExpiryMonthAndYear]),
      [ENV.accountBillingZero.creditCardForm.securityCodeFormControlName]:new FormControl(
        "",Validators.required
      )
    })
  }


}
