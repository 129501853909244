import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilityService } from '@core/utility/utility.service';
import {ENV } from '@env/environment';
import { BehaviorSubject, catchError, concatMap, filter, from, iif, map, of, ReplaySubject, Subject, take, takeUntil, tap, throwError } from 'rxjs';
import { FirebaseService } from '../firebase/firebase.service';
import { signInWithPopup, onAuthStateChanged, User } from 'firebase/auth';
import { BaseService } from '@core/base/base.service';
import { SpecificService } from '@core/specific/specific.service';
import { WMLImage } from '@windmillcode/angular-wml-components-base';
import { ListUsersUIRequestBody, ListUsersUIRequestBodyTypeEnum, ListUsersUIResponseBody, listUsersLoad, listUsersSuccess } from './listUsers';
import { ListCardsUIRequestBody, ListCardsUIResponseBody, listCardsLoad, listCardsSuccess } from './listCards';
import { DeleteCardsUIRequestBody, DeleteCardsUIResponseBody, deleteCardsLoad, deleteCardsSuccess } from './deleteCards';
import { DeleteUserUIRequestBody, DeleteUserUIResponseBody, deleteUserLoad, deleteUserSuccess } from './deleteUser';
import { ExportUsersUIRequestBody, ExportUsersUIResponseBody, exportUsersLoad, exportUsersSuccess } from './exportUsers';
import { isNetworkConnectionGood } from '@core/utility/common-utils';
import { APIServerError, UserCanceledAuthError } from '@core/utility/error-utils';
import { SocketioService } from '../socketio/socketio.service';


import { GetCardServerSessionIdUIRequestBody, GetCardServerSessionIdUIResponseBody, getCardServerSessionIdLoad, getCardServerSessionIdSuccess } from './getCardServerSessionId';
import { AddCardToUserUIRequestBody, AddCardToUserUIResponseBody, addCardToUserLoad, addCardToUserSuccess } from './addCardToUser';


import { UpdateAdressesUIRequestBody,UpdateAdressesUIResponseBody,updateAdressesLoad, updateAdressesSuccess } from './updateAdresses';
import { UpdateUserUIRequestBody,UpdateUserUIResponseBody,updateUserLoad, updateUserSuccess } from './updateUser';



@Injectable({
  providedIn: 'root'
})
export class AccountsService {

  constructor(
    public http:HttpClient,
    public utilService:UtilityService,
    public firebaseService:FirebaseService,
    public baseService:BaseService,
    public specificService:SpecificService,
    public injector:Injector,
    public socketioService:SocketioService

  ) {}

  users:AccountsServiceUser[] = []
  currentUser:AccountsServiceUser
  paymentMethodsIsReadySubj = new BehaviorSubject<boolean>(false)
  paymentMethodsIsReadyObs$ = this.paymentMethodsIsReadySubj
  .pipe(
    filter((val) => val === true),
    take(1)
  )
  onAuthStateChangedEvent = new ReplaySubject<void>(Infinity)

  authUserViaLogoClick(
    ngUnsub: Subject<void>,
    provider:keyof FirebaseService["idpInfo"],
  ) {
    this.authenticateViaFirebaseProvider(
      provider
    )
      .pipe(
        takeUntil(ngUnsub),
        catchError((err) => throwError(() => {
          this.baseService.closeOverlayLoading()
          return new UserCanceledAuthError(err)})
        ),
      )
      .subscribe();
  }

  authenticateViaFirebaseProvider = (
    provider:keyof FirebaseService["idpInfo"])=>{

    let idpInfo = this.firebaseService.idpInfo[provider]
    this.baseService.openOverlayLoading()
    return from(
      signInWithPopup(this.firebaseService.auth, idpInfo.provider)
    )
  }

  proceed= ()=>{
    this.onAuthStateChangedEvent.next()
    this.baseService.closeOverlayLoading()
    if([
      "signUp",
      "signIn",
    ].map((val)=>{
      return ENV.nav.urls[val]
    }).includes(this.utilService.router.url)){

      this.utilService.router.navigateByUrl(ENV.nav.urls.account)
    }
  }

  onAuthStateChangedCallback =(user) => {

    if (user) {
      this.users.push(
        new AccountsServiceUser({
          user
        })
      )
      this.currentUser = this.users.at(-1)
      if(!isNetworkConnectionGood()){
        this.proceed()
        return
      }
      this.baseService.openOverlayLoading()
      this.listUsers(new ListUsersUIRequestBody({
        // @ts-ignore
        accessToken:user.stsTokenManager.accessToken,
        email:this.currentUser.displayEmail,
        type:ListUsersUIRequestBodyTypeEnum.CREATEACCTIFNOTEXISTS,

      }))
      .pipe(
        tap({
          next:(res:ListUsersUIResponseBody)=>{
            // this is where you update the user with square information
            let userInfo = res.data[0]
            this.currentUser.projects =userInfo?.projects ?? []
            this.currentUser.address = userInfo?.addresses?.[0]
            this.currentUser.emailNotificationsIsPresent = userInfo?.emailNotificationsIsPresent
            this.currentUser.smsNotificationsIsPresent = userInfo?.smsNotificationsIsPresent
            this.proceed()
          },
          error:(err)=>{
            this.baseService.closeOverlayLoading()
          }
        }),
      )
      .subscribe()

    }
    else{
      this.users = []
      this.currentUser = null
      this.onAuthStateChangedEvent.next()
    }

  }

  manageUsersLoginInfo = ()=>{

    onAuthStateChanged(
      this.firebaseService.auth,
      this.onAuthStateChangedCallback
    );
  }

  signOutViaFirebase =()=>{
    return from(this.firebaseService.auth.signOut())
    .pipe(
      tap({
        next:()=>{
          this.utilService.router.navigateByUrl(ENV.nav.urls.home)
          this.users.pop()
          this.currentUser = null
          this.baseService.createWMLNote("AccountsService.wmlNotify.loggedOutSuccess")
        },
        error:()=>{
          this.baseService.createWMLNote("AccountsService.wmlNotify.loggedOutError")
        }
      })
    )

  }



  listUsers = (uiBody = new ListUsersUIRequestBody(),raw = false)=>{

    return iif(
    ()=>ENV.accountsService.listUsers.automate,
      of(new ListUsersUIResponseBody()),

      listUsersLoad(uiBody)
        .pipe(
          concatMap((apiBody)=>{
            return this.http
            .post(ENV.accountsService.listUsers.url(),apiBody)
            .pipe(raw ? tap() : map(listUsersSuccess))
        })
      )
    )
  }

  deleteUser = (uiBody =new DeleteUserUIRequestBody(),raw =false)=>{
    return iif(
      ()=>ENV.accountsService.deleteUser.automate,
      of(new DeleteUserUIResponseBody()),
      deleteUserLoad(uiBody)
        .pipe(
          concatMap((apiBody)=>{
            return this.http
              .delete(ENV.accountsService.deleteUser.url(),{body:apiBody})
              .pipe(raw ? tap() : map(deleteUserSuccess))
          })
        )
      )
  }

  exportUsers = (uiBody =new ExportUsersUIRequestBody(),raw =false)=>{
    return iif(
      ()=>ENV.accountsService.exportUsers.automate,
      of(new ExportUsersUIResponseBody()),
      exportUsersLoad(uiBody)
        .pipe(
          concatMap((apiBody)=>{
            return this.http
              .post(ENV.accountsService.exportUsers.url(),apiBody)
              .pipe(raw ? tap() : map(exportUsersSuccess))
          })
        )
      )
  }

  listCards = (uiBody = new ListCardsUIRequestBody(),raw = false)=>{

    uiBody.accessToken = this.currentUser.accessToken
    return iif(
    ()=>ENV.accountsService.listCards.automate,
      of(new ListCardsUIResponseBody()),

      listCardsLoad(uiBody)
        .pipe(
          concatMap((apiBody)=>{
            return this.http
            .post(ENV.accountsService.listCards.url(),apiBody)
            .pipe(
              concatMap((res:any)=>{
                return iif(
                  ()=>res.msg === ENV.endpointMsgCodes.respViaSocketio,
                  this.socketioService.checkForAuthenticationEvent
                  .pipe(
                    concatMap((res)=>{
                      if(res?.code === 500){
                        return throwError(()=>{return new APIServerError(res.description)})
                      }
                      else{
                        // its like this because it pipes back into the outer listCards call which will transform the data
                        return this.listCards(uiBody,true)
                      }
                    })
                  ),
                  of(res)
                )
              }),
              raw ? tap() : map(listCardsSuccess)
            )
        })
      )
    )
  }

  deleteCards = (uiBody = new DeleteCardsUIRequestBody(),raw = false)=>{

    return iif(
    ()=>ENV.accountsService.deleteCards.automate,
      of(new DeleteCardsUIResponseBody()),

      deleteCardsLoad(uiBody)
        .pipe(
          concatMap((apiBody)=>{
            return this.http
            .delete(ENV.accountsService.deleteCards.url(),{body:apiBody})
            .pipe(raw ? tap() : map(deleteCardsSuccess))
        })
      )
    )
  }

  getCardServerSessionId = (uiBody = new GetCardServerSessionIdUIRequestBody(),raw =false)=>{
    return iif(
      ()=>ENV.accountsService.getCardServerSessionId.automate,
      of(new GetCardServerSessionIdUIResponseBody()),
      getCardServerSessionIdLoad(uiBody)
        .pipe(
          concatMap((apiBody)=>{
            return this.http
              .post(ENV.accountsService.getCardServerSessionId.url(),apiBody)
              .pipe(raw ? tap() : map(getCardServerSessionIdSuccess))
          })
        )
      )
  }

  addCardToUser = (uiBody = new AddCardToUserUIRequestBody(),raw =false)=>{
    return iif(
      ()=>ENV.accountsService.addCardToUser.automate,
      of(new AddCardToUserUIResponseBody()),
      addCardToUserLoad(uiBody)
        .pipe(
          concatMap((apiBody)=>{
            return this.http
              .put(ENV.accountsService.addCardToUser.url(),apiBody)
              .pipe(raw ? tap() : map(addCardToUserSuccess))
          })
        )
      )
  }

  updateAdresses = (uiBody = new UpdateAdressesUIRequestBody(),raw =false)=>{
    return iif(
      ()=>ENV.accountsService.updateAdresses.automate,
      of(new UpdateAdressesUIResponseBody()),
      updateAdressesLoad(uiBody)
        .pipe(
          concatMap((apiBody)=>{
            return this.http
              .put(ENV.accountsService.updateAdresses.url(),apiBody)
              .pipe(raw ? tap() : map(updateAdressesSuccess))
          })
        )
      )
  }

  updateUser = (uiBody = new UpdateUserUIRequestBody(),raw =false)=>{

    return iif(
      ()=>ENV.accountsService.updateUser.automate,
      of(new UpdateUserUIResponseBody()),
      updateUserLoad(uiBody)
        .pipe(
          concatMap((apiBody)=>{
            return this.http
              .put(ENV.accountsService.updateUser.url(),apiBody)
              .pipe(raw ? tap() : map(updateUserSuccess))
          })
        )
      )
  }

}


export class AccountsServiceUser {
  constructor(params:Partial<AccountsServiceUser>={}){
    Object.assign(
      this,
      {
        ...params
      }
    )
  }
  user:User
  fallbackAvatarURL = "assets/media/app/logo-no-bg.png"
  rewardsPoints = 0
  courseTier = "Basic"
  completedCourses= []
  startedCourses = []
  orders = []
  projects:ListUsersUIResponseBody["data"][number]["projects"] = []
  subscriptions = []
  paymentMethods:ListCardsUIResponseBody["data"][number] = []
  emailNotificationsIsPresent= false
  smsNotificationsIsPresent = false
  address?:ListUsersUIResponseBody["data"][number]["addresses"]
  get id(){
    return this.user.uid
  }
  get accessToken(){
    // @ts-ignore
    return this.user.stsTokenManager.accessToken
  }

  get profileUrl (){
    return new WMLImage({
      src:this.user.photoURL ?? this.fallbackAvatarURL,
      alt:"AccountsService.profileURLAlt"
    })
  }
  get userName(){
    return this.user.displayName
  }
  get displayEmail(){
    return this.user.email ?? "global.na"
  }
  get businessEmail(){
    return this.user.email
  }
  get isEmailVerified(){
    return this.user.emailVerified ?? null
  }
  get displayPhone(){
    return this.user.phoneNumber ?? "global.na"
  }
  get businessPhone(){
    return this.user.phoneNumber
  }
  get accountCreationDate(){
    let {creationTime} =this.user.metadata

    return  this.formateDate0(creationTime);
  }
  get lastSignInDate(){
    let {lastSignInTime} = this.user.metadata
    return  this.formateDate0(lastSignInTime);
  }
  private formateDate0(creationTime: string) {
    let date = new Date(creationTime);
    let month = String(date.getMonth() + 1).padStart(2, '0');
    let day = String(date.getDate()).padStart(2, '0');
    let year = date.getFullYear();
    let formattedDate = `${month}/${day}/${year}`;
    return formattedDate;
  }




}
