import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilityService } from '@core/utility/utility.service';
import {ENV } from '@env/environment';
import { retry,  concatMap, iif, map, of, tap, catchError, throwError } from 'rxjs';
import {   ListLabsUIRequestBody,ListLabsUIResponseBody,listLabsFail,listLabsLoad, listLabsSuccess } from './listLabs';
import { BaseService, ScolllPaginationParams } from '@core/base/base.service';
import { GetMarkdownTextUIRequestBody,getMarkdownTextLoad } from './getMarkdownText';
import { ServiceUnavailableError } from '@core/utility/error-utils';


@Injectable({
  providedIn: 'root'
})
export class LabsService {

  constructor(

    public baseService:BaseService,
    public http:HttpClient,
    public utilService:UtilityService
  ) { }

  listLabsRequests = new ListLabsUIRequestBody({pageSize:30})
  listLabs = (scrollPaginationObj?:ScolllPaginationParams,uiBody = this.listLabsRequests,raw =false)=>{
    return iif(
      ()=>ENV.labsService.listLabs.automate,
      of(new ListLabsUIResponseBody()),
      listLabsLoad(uiBody)
        .pipe(
          retry(3),
          concatMap((apiBody)=>{
            return this.http
              .post(ENV.labsService.listLabs.url(),apiBody)
              .pipe(
                retry(3),
                tap({
                  next:(apiBody:any)=>{
                    this.baseService.notifyUserThatThereAreNoRecords(apiBody, uiBody);
                  }
                }),
                raw ? tap() : map(listLabsSuccess(scrollPaginationObj,uiBody)),
                this.baseService.closeOverlayLoadingViaRxjsFinalize,
                catchError((err) => throwError(() => {
                  listLabsFail(uiBody)
                  if(err.status === 503){
                    return new ServiceUnavailableError(err)
                  }
                  return err
                })),
              )
          })
        )
      )
    }


  getMarkdownText = (uiBody = new GetMarkdownTextUIRequestBody(),raw =false)=>{
    return iif(
      ()=>ENV.labsService.getMarkdownText.automate,
      of(""),
      getMarkdownTextLoad(uiBody)
        .pipe(
          concatMap((apiBody)=>{
            return this.http
              .get(uiBody.url,{
                headers:{'Accept':'text/html'},
                responseType:'text'
              })
          })
        )
      )
    }




}
