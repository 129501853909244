import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilityService } from '@core/utility/utility.service';
import {ENV } from '@env/environment';
import { concatMap, iif, map, Observable, of, retry, Subject, tap } from 'rxjs';
import {WmlButtonZeroParams} from "@windmillcode/angular-wml-button-zero";
import { replaceValuesWithPaths, WMLCustomComponent, WMLImage, WMLUIProperty } from '@windmillcode/angular-wml-components-base';
import { FormsService } from '../forms/forms.service';

import { WMLPanelItemParams, WMLPanelParams } from '@windmillcode/angular-wml-panel';
import { StoreServiceItemPrice} from './models';

import {purchaseProductsLoad, purchaseProductsSuccess, PurchaseProductsUIRequestBody, PurchaseProductsUIResponseBody,} from "./purchaseProducts";
import { WmlTableZeroParams, WMLTableZeroTablePredicateReturnValue } from '@windmillcode/angular-wml-table-zero';
import { BaseService } from '@core/base/base.service';
import {  updateWebStorage } from '@core/utility/common-utils';
import { AccountsService } from '../accounts/accounts.service';
import enTranslations from "src/assets/i18n/en.json";
import { afterCursorRequestModifierPredicate, WMLDataSource } from '@core/utility/data-source-utils';
import { ListProductsAPIResponseBody, ListProductsUIRequestBody, listProductsLoad } from './listProducts';
import { listProductsForProductsSuccess } from './listProductsForProducts';
import { PanelCartContainerZeroComponent, PanelCartContainerZeroParams } from '@shared/components/panel-cart-container-zero/panel-cart-container-zero.component';
import { PanelCartZeroItemParams } from '@shared/components/panel-cart-zero/panel-cart-zero.component';
import { ColorOptionZeroParams } from '@shared/components/color-option-zero/color-option-zero.component';
import { DateTimeZero } from '@core/utility/date-utils';




@Injectable({
  providedIn: 'root'
})
export class StoreService {

  constructor(
    public http:HttpClient,
    public utilService:UtilityService,
    public formsService:FormsService,
    public baseService:BaseService,
    public accountsService:AccountsService
  ) {

  }

  useAiPricing = false

  carts:Array<PanelCartZeroItemParams[]> = [[]]
  currentCart = this.carts[0]
  cartSubj = new Subject<"addCart"|"removeCart"|"addItem"|"removeItem">()
  cartPanelItemParams= (()=>{
    let panelItem = new WMLPanelItemParams({
      custom:new WMLCustomComponent({
        cpnt:PanelCartContainerZeroComponent
      })
    })
    panelItem.custom.params =  new PanelCartContainerZeroParams({
      close:panelItem.close
    })

    return panelItem
  })()
  cartPanelParams = new WMLPanelParams({
    container:new WMLUIProperty({
      class:"StoreServiceView"
    }),
    items:[this.cartPanelItemParams]
  })

  addToCart = (params:{
    id:string,quantity:number,color?:string,size?:string,
  })=>{
    let {id,quantity,color,size} = params
    return this.listProductsDataSource.
    queryDataFromSource(
      new ListProductsUIRequestBody({
        pageSize:1,
        filter:[
          {key:"id",value:id}
        ]
      })
    )
    .pipe(
      tap((res)=>{
        let [data] = res.data.data
        let variant = data.variants.find((variant)=>{
          let hasSize =  variant.option_combos.find((option)=>{
            return option.name ==="Size" && option.value === size || size === "None"
          })
          let hasColor =  variant.option_combos.find((option)=>{
            return option.name ==="Color" && option.value === color || color === "None"
          })
          return hasColor && hasSize
        })
        let cartItem = new PanelCartZeroItemParams({
          title:data.title,
          size:new WMLUIProperty({
            text:size
          }),
          displayImg:new WMLImage({
            src:data.image_urls[0].src,
            alt:data.image_urls[0].alt,
          }),
          quantity,
          color:new ColorOptionZeroParams({
            color:new WMLUIProperty({
              style:{
                backgroundColor:color
              }
            })
          }),
          price:new StoreServiceItemPrice({
            business: data.price.business,
            currency: data.price.currency,
            serverDisplay: data.price.display
          }),
          variant
        })
        this.currentCart.push(cartItem)
        this.cartSubj.next("addItem")

      })
    )

  }
  buyNow = ()=>{

    ["PROD"].includes(ENV.type) ? this.baseService.openFeatureIsComingSoon():
    this.utilService.router.navigateByUrl(ENV.nav.urls.checkoutZero)
  }

  generateAddToCartBtn = (clickMethod )=> new WmlButtonZeroParams({
    text:new WMLUIProperty({
      text:"StoreService.addToCartBtn"
    }),
    button:new WMLUIProperty({
      click:clickMethod
    })
  })
  generateBuyNowBtn = (clickMethod = this.buyNow,id ="")=> new WmlButtonZeroParams({
    text:new WMLUIProperty({
      text:"StoreService.buyNowBtn"
    }),
    button:new WMLUIProperty({
      id,
      click:clickMethod
    })
  })


  clickToggleUseAIPricingBtn = (init:any =false)=>{


    updateWebStorage(
      localStorage,
      ENV.classPrefix.products,
      (webStorage)=>{
        if(init !== true){
          webStorage.toggleAIPricing = !this.useAiPricing
          this.useAiPricing = webStorage.toggleAIPricing
        }
        else{
          this.useAiPricing = webStorage.toggleAIPricing ?? this.useAiPricing
        }
        localStorage.setItem(ENV.classPrefix.products,JSON.stringify(webStorage))
        this.productTableParams.instructionStackCachingLimit = this.useAiPricing? 0 :3
        if(init !== true){
          this.utilService.getWindow().location.reload()
        }
      }
    )

  }

  navToProductDetailsPage =(id:string)=>{
    this.utilService.router.navigate([ENV.nav.urls.productDetailZero,id])
  }

  listProducts = (uiBody =new ListProductsUIRequestBody())=>{


    let {visitedLinks}= this.baseService;
    // @ts-ignore
    let {darkMode} = JSON.parse(localStorage.getItem(ENV.classPrefix.app))


    return iif(
    ()=>ENV.storeService.listProducts.automate,
      of(new ListProductsAPIResponseBody()),

      listProductsLoad(uiBody,visitedLinks,darkMode,this.useAiPricing,this.accountsService.currentUser)
      .pipe(
        concatMap((apiBody)=>{
          this.baseService.openOverlayLoading()
          return this.http
          .post(ENV.storeService.listProducts.url(),apiBody)
          .pipe(
            retry(2),
            this.baseService.closeOverlayLoadingViaRxjsFinalize
          )

        })
      ) as Observable<ListProductsAPIResponseBody>

    )
  }

  listProductsDataSource= new WMLDataSource({
    getFromSink:this.listProducts,
    requestModifierPredicate :afterCursorRequestModifierPredicate,
    webStorageObj:{
      key:"ListProdcutsDataSource",
      expiry:new DateTimeZero({
        hours:2
      })
    }
  })

  productTableParams:WmlTableZeroParams =  new WmlTableZeroParams({
    paramsTextContent:replaceValuesWithPaths(enTranslations.ProductsZero.WMLTable,"ProductsZero.WMLTable."),
    instructionStackCachingLimit:this.useAiPricing?  0:3,
    currentRowType:0,
    changeRowTypeBasedOnWindowSizeIsPresent:false,

    tablePredicate:  (req= new ListProductsUIRequestBody({pageSize:10}))=>{
      return this.listProductsDataSource.
      queryDataFromSource(req)
      .pipe(
        map(listProductsForProductsSuccess(
          this.navToProductDetailsPage
        )),
        map((res)=>{
          let result =  new WMLTableZeroTablePredicateReturnValue({
            req,
            res
          })

          return result
        })
      )

    },

  })

  purchaseProducts = (uiBody:PurchaseProductsUIRequestBody,raw = false)=>{

    this.baseService.openOverlayLoading()
    return iif(
    ()=>ENV.storeService.purchaseProducts.automate,
      of(new PurchaseProductsUIResponseBody()),

      purchaseProductsLoad(uiBody)
        .pipe(
          concatMap((apiBody)=>{
            return this.http
            .post(ENV.storeService.purchaseProducts.url(),apiBody)
            .pipe(
              raw ? tap() : tap(purchaseProductsSuccess),
            this.baseService.closeOverlayLoadingViaRxjsFinalize
          )
        })
      )
    )
  }





}




