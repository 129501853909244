// angular
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding,  Input, ViewChild, ViewContainerRef   } from '@angular/core';

// services
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';

// rxjs
import { Subject } from 'rxjs';

// wml-components
import { generateClassPrefix } from '@windmillcode/angular-wml-components-base';


// misc
import {ENV } from '@env/environment';
import { WMLField } from '@windmillcode/angular-wml-field';



@Component({

  selector: 'card-expiry-input-zero',
  templateUrl: './card-expiry-input-zero.component.html',
  styleUrls: ['./card-expiry-input-zero.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush,

})
export class CardExpiryInputZeroComponent  {

  constructor(
    public cdref:ChangeDetectorRef,

    public utilService:UtilityService,
    public baseService:BaseService

  ) { }

  classPrefix = generateClassPrefix('CardExpiryInputZero')
  idPrefix = generateClassPrefix(ENV.idPrefix.cardExpiryInputZero)
  @Input('params') params: CardExpiryInputZeroParams = new CardExpiryInputZeroParams()
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  @HostBinding('id') myId:string = this.idPrefix()
  ngUnsub= new Subject<void>()
  @ViewChild("year",{read:ViewContainerRef}) year :ViewContainerRef
  @ViewChild("month",{read:ViewContainerRef}) month :ViewContainerRef

  focusSibling = (target, direction, callback?)=> {
    const nextTarget = target[direction];
    nextTarget?.focus();
    callback?.(nextTarget);
  }
  inputMatchesPattern = (e)=> {
    const {
      value,
      selectionStart,
      selectionEnd,
      pattern
    } = e.target;

    const character = String.fromCharCode(e.which);
    const proposedEntry = value.slice(0, selectionStart) + character + value.slice(selectionEnd);
    const match = RegExp(pattern).exec(proposedEntry);

    return e.metaKey || // cmd/ctrl
      e.which <= 0 || // arrow keys
      e.which == 8 || // delete key
      match && match["0"] === match.input; // pattern regex isMatch - workaround for passing [0-9]* into RegExp
  };
  onMonthInput =(event:any) => {

    const value = event.target.value.toString();
    // adds 0 to month user input like 9 -> 09
    if (value.length === 1 && value > 1) {
        event.target.value = "0" + value;
    }
    // bounds
    if (value === "00") {
        event.target.value = "01";
    } else if (value > 12) {
        event.target.value = "12";
    }
    // if we have a filled input we jump to the year input
    2 <= event.target.value.length && this.focusSibling(event.target, "nextElementSibling");
    event.stopImmediatePropagation();
  }
  onYearInputKeydown = (event:any) => {
    // if the year is empty jump to the month input
    if (event.key === "Backspace" && event.target.selectionStart === 0) {
      this.focusSibling(event.target, "previousElementSibling");
      event.stopImmediatePropagation();
    }
  }
  onInputKeyPress = e => {
    if (!this.inputMatchesPattern(e)) {
      return e.preventDefault();
    }
  }
  captureInput = ()=>{
    let formGroup = this.params.wmlField.getReactiveFormControl()
    formGroup.patchValue({
      month:this.month.element.nativeElement.value,
      year: this.year.element.nativeElement.value
    })

  }


  ngOnInit(): void {
  }

  ngOnDestroy(){
    this.ngUnsub.next();
    this.ngUnsub.complete()
  }

}



export class CardExpiryInputZeroParams {
  constructor(params:Partial<CardExpiryInputZeroParams>={}){
    let origParams = Object.entries(params)
    .filter(([key,val]) => {
      return !key.startsWith('param');
    });
    Object.assign(this, { ...Object.fromEntries(origParams) })
  }
  wmlField:WMLField
}


