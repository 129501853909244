// angular
import { ChangeDetectorRef, Component, HostBinding,  Input   } from '@angular/core';

// services
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';

// rxjs
import { Subject } from 'rxjs';

// wml-components
import { WMLUIProperty, generateClassPrefix } from '@windmillcode/angular-wml-components-base';

// misc
import {ENV } from '@env/environment';
import { WMLOptionItemParams } from '@windmillcode/angular-wml-options';

@Component({
  selector: 'color-option-zero',
  templateUrl: './color-option-zero.component.html',
  styleUrls: ['./color-option-zero.component.scss'],
})
export class ColorOptionZeroComponent  {

  constructor(
    public cdref:ChangeDetectorRef,
    public utilService:UtilityService,
    public baseService:BaseService
  ) { }

  classPrefix = generateClassPrefix('ColorOptionZero')
  idPrefix = generateClassPrefix(ENV.idPrefix.colorOptionZero)
  @Input('params') params: ColorOptionZeroParams = new ColorOptionZeroParams()
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  @HostBinding('id') myId:string = this.idPrefix()
  ngUnsub= new Subject<void>()




  ngOnDestroy(){
    this.ngUnsub.next();
    this.ngUnsub.complete()
  }

}



export class ColorOptionZeroParams {
  constructor(params:Partial<ColorOptionZeroParams>={}){
    Object.assign(
      this,
      {
        ...params
      }
    )

    if(this.wmlOptionItem){
      this.wmlOptionItem.toggleClass  = this.wmlOptionItem.toggleClass?? "ColorOptionZeroMainPod0"
    }

  }
  wmlOptionItem!:WMLOptionItemParams
  color = new WMLUIProperty({})


}


