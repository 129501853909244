// angular
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding,  Input, ViewChildren, QueryList, ElementRef   } from '@angular/core';


// services
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';

// rxjs
import { Subject } from 'rxjs';
import { takeUntil,tap } from 'rxjs/operators';

// wml-components
import { WMLUIProperty, generateClassPrefix } from '@windmillcode/angular-wml-components-base';


// misc
import {ENV } from '@env/environment';
import { WMLField } from '@windmillcode/angular-wml-field';
import { repeatString } from '@core/utility/string-utils';
import { FormControl } from '@angular/forms';



@Component({

  selector: 'card-number-input-zero',
  templateUrl: './card-number-input-zero.component.html',
  styleUrls: ['./card-number-input-zero.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush,

})
export class CardNumberInputZeroComponent  {

  constructor(
    public cdref:ChangeDetectorRef,

    public utilService:UtilityService,
    public baseService:BaseService

  ) { }

  classPrefix = generateClassPrefix('CardNumberInputZero')
  idPrefix = generateClassPrefix(ENV.idPrefix.cardNumberInputZero)
  @Input('params') params: CardNumberInputZeroParams = new CardNumberInputZeroParams()
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  @HostBinding('id') myId:string = this.idPrefix()
  ngUnsub= new Subject<void>()
  @ViewChildren("numberSection")  numberSection:QueryList<ElementRef>
  numberSectionElements = []
  formControl!:FormControl
  repeatString = repeatString
  addZeroesToDigits =(number,amount)=> {
    let numberString = number.toString();
    let zerosToAdd = amount - numberString.length;
    while (zerosToAdd > 0) {
        numberString += "0";
        zerosToAdd--;
    }
    return numberString;
  }

  captureInput =()=>{
    let result = this.numberSectionElements
    .reduce((acc,x,i)=>{
      let  {value} = x.nativeElement
      return acc + this.addZeroesToDigits(value,this.params.sections[i])
    },"")


    this.formControl.patchValue(result)

  }

  listenForNumberSections = ()=>{

    return this.numberSection.changes
    .pipe(
      takeUntil(this.ngUnsub),
      tap((res)=>{
        this.numberSectionElements = res.toArray()
      })
    )
  }

  listenForFormControlChanges =()=>{
    return this.formControl.valueChanges
    .pipe(
      takeUntil(this.ngUnsub),
      tap((res:string)=>{
        let startIndex= 0
        this.params.sections
        .forEach((section)=>{
          section.value = (res??"").slice(startIndex,startIndex+section.amount)
          startIndex += section.amount
        })
        this.cdref.detectChanges()
      })
    )

  }

  getNumberSections() {
    this.listenForNumberSections().subscribe();
    this.numberSection.notifyOnChanges();
  }

  ngOnInit(): void {
    this.formControl = this.params.wmlField.getReactiveFormControl() as FormControl
    this.listenForFormControlChanges().subscribe()
  }

  ngAfterViewInit():void{

    this.getNumberSections();
  }



  ngOnDestroy(){
    this.ngUnsub.next();
    this.ngUnsub.complete()
  }

}

export class CardNumberInputZeroSection  extends WMLUIProperty{
  constructor(params: Partial<CardNumberInputZeroSection> = {}) {
    super()
    let origParams = Object.entries(params)
      .filter(([key,val]) => {
        return !key.startsWith('param');
      });
    Object.assign(this, { ...Object.fromEntries(origParams) });
  }
  amount = 4
  delimiter = "X"
  override style:Partial<CSSStyleDeclaration> ={
    flex:"0 1 20%"
  }
}

export class CardNumberInputZeroParams {
  constructor(params:Partial<CardNumberInputZeroParams & {
    paramAmounts:Array<number>,
    paramStyles:Array<Partial<CSSStyleDeclaration>>}>={}){
    let origParams = Object.entries(params)
    .filter(([key,val]) => {
      return !key.startsWith('param');
    });
    Object.assign(this, { ...Object.fromEntries(origParams) })
    if (params.paramAmounts) {
      this.sections = params.paramAmounts.map((amount, index0) => {
        const style = params?.paramStyles?.[index0] ?? {} ;
        return new CardNumberInputZeroSection({ amount, style });
      });
    } else if (params.paramStyles) {
      this.sections = params.paramStyles.map((style) => {
        return new CardNumberInputZeroSection({ style });
      });
    }


  }
  wmlField = new WMLField()
  sections = Array(4)
  .fill(null)
  .map((nullVal,index0)=>{
    return new CardNumberInputZeroSection({})
  })
}


