// angular
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding,  Input, ViewContainerRef, ViewChild   } from '@angular/core';

// services
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';

// rxjs
import { Subject, fromEvent } from 'rxjs';
import { takeUntil,tap } from 'rxjs/operators';

// wml-components
import { WMLUIProperty, generateClassPrefix } from '@windmillcode/angular-wml-components-base';

// misc
import {ENV } from '@env/environment';


@Component({
  selector: 'date-picker-zero',
  templateUrl: './date-picker-zero.component.html',
  styleUrls: ['./date-picker-zero.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush,
})
export class DatePickerZeroComponent  {

  constructor(
    public cdref:ChangeDetectorRef,
    public utilService:UtilityService,
    public baseService:BaseService
  ) { }

  classPrefix = generateClassPrefix('DatePickerZero')
  idPrefix = generateClassPrefix(ENV.idPrefix.datePickerZero)
  @Input('params') params: DatePickerZeroParams = new DatePickerZeroParams()
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  @HostBinding('id') myId:string = this.idPrefix()
  @ViewChild("datePicker",{read:ViewContainerRef}) datePicker
  ngUnsub= new Subject<void>()
  picker
  dateInput = new WMLUIProperty({})

  toggleDatePicker = ()=>{
    this.picker.isVisible() ? this.picker.hide(): this.picker.show()
    this.cdref.detectChanges()
  }

  listenForWindowResize = ()=>{
    return fromEvent(window,'resize')
    .pipe(

      takeUntil(this.ngUnsub),
      tap(()=>{
        if(this.picker.isVisible()){
          this.picker.adjustPosition()
          this.cdref.detectChanges()
        }
      })
    )

  }

  onSelectDate =()=>{
    this.dateInput.text = this.picker.toString('MM-DD-YYYY')
    this.cdref.detectChanges()
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(){

    // this.picker = new Pikaday({
    //   field:this.datePicker.element.nativeElement,
    //   onSelect:this.onSelectDate
    // })

    this.listenForWindowResize().subscribe()
    this.cdref.detectChanges()
  }

  ngOnDestroy(){
    this.ngUnsub.next();
    this.ngUnsub.complete()
  }

}



export class DatePickerZeroParams {
  constructor(params:Partial<DatePickerZeroParams>={}){
    let origParams = Object.entries(params)
    .filter(([key,val]) => {
      return !key.startsWith('param');
    });
    Object.assign(this, { ...Object.fromEntries(origParams) })
  }

}


